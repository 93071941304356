import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"
import styles from "./footer.module.scss"

const Footer = (props) => (
    <footer id="footer">
        <div className={styles.container}>
            <div className={styles.blurb}>
                <AniLink paintDrip to="/" duration={1} hex="#fff">antoniolee.me</AniLink>
                <h1>Bridging the gap between design and development.</h1>
                <p>Copyright 2014-2024 ©</p>
            </div>
            <div className={styles.nav}>
                <div className={styles.explore}>
                    <h4>Explore</h4>
                    <ul>
        <li>
          <AniLink
            paintDrip to="/about/"
            className={styles.hiddenLink}
            activeClassName={styles.active}
            duration={1} 
            hex="#000"
          >
            about
          </AniLink>
        </li>
        <li>
          <AniLink
            paintDrip to="/work/"
            className={styles.hiddenLink}
            activeClassName={styles.active}
            duration={1} 
            hex="#000"
          >
            work
          </AniLink>
        </li>
        <li>
          <AniLink
            paintDrip to="/resources/"
            className={styles.hiddenLink}
            activeClassName={styles.active}
            duration={1} 
            hex="#000"
          >
            resources
          </AniLink>
        </li>
        <li>
          <AniLink
            paintDrip to="/resume/"
            className={styles.hiddenLink}
            activeClassName={styles.active}
            duration={1} 
            hex="#000"
          >
            resume
          </AniLink>
        </li>
      </ul>
                </div>
                <div className={styles.social}>
                    <h4>Social</h4>
                    <ul>
                        <li><a href="https://codepen.io/antoniolee">CodePen</a></li>
                        <li><a href="https://www.linkedin.com/in/antoniohlee/">LinkedIn</a></li>
                        <li><a href="https://www.instagram.com/antoniohlee/">Instagram</a></li>
                        <li><a href="https://twitter.com/antoniohlee">Twitter</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
