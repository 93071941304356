import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styles from "./header.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const Header = props => (
  <header>
    <div className={styles.container}
      // data-sal="slide-down"
      // data-sal-delay="400"
      // data-sal-easing="ease"
      // data-sal-duration="600"
    >
      <div>
        <AniLink
          paintDrip
          to="/"
          className={styles.logo}
          activeClassName={styles.active}
          duration={1}
          hex="#fff"
        >
          antoniolee.me
        </AniLink>
      </div>
      <div className={styles.links}>
        <ul>
          <li>
            <AniLink
              paintDrip
              to="/about/"
              className={styles.hiddenLink}
              activeClassName={styles.active}
              duration={1}
              hex="#000"
            >
              about
            </AniLink>
          </li>
          <li>
            <AniLink
              paintDrip
              to="/work/"
              className={styles.hiddenLink}
              activeClassName={styles.active}
              duration={1}
              hex="#000"
            >
              work
            </AniLink>
          </li>
          <li>
            <AniLink
              paintDrip
              to="/resources/"
              className={styles.hiddenLink}
              activeClassName={styles.active}
              duration={1}
              hex="#000"
            >
              resources
            </AniLink>
          </li>
          <li>
            <AniLink
              paintDrip
              to="/resume/"
              className={styles.hiddenLink}
              activeClassName={styles.active}
              duration={1}
              hex="#000"
            >
              resume
            </AniLink>
          </li>
        </ul>
        {/* <Link to="#0" className={styles.menuIcon}><FontAwesomeIcon icon={faBars} /></Link> */}
      </div>
    </div>
  </header>
)

export default Header
