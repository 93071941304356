import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import svgCodepen from "../../images/codepen.svg"
import svgInstagram from "../../images/instagram.svg"
import svgLinkedin from "../../images/linkedin.svg"
import svgTwitter from "../../images/twitter.svg"

import cx from "classnames"
import styles from "./menu.module.scss"
import iconClose from "./icon-close.svg"
import { faBars } from "@fortawesome/free-solid-svg-icons"


class Menu extends React.Component {
    constructor() {
        super();
        this.state = {
            menu: {
                // width: "0%",
                maxWidth: "0",
            }
        };
    }

    openMenu = () => {
        console.log("open menu pressed");
        this.setState({
            menu: {
                // width: "100%",
                maxWidth: "640px",
            }
        });
    };

    closeMenu = () => {
        console.log("close menu pressed");
        this.setState({
            menu: {
                // width: "0%",
                maxWidth: "0",
            }
        });
    };


    render() {
        return(
            <div className={styles.menuContainer}>
                <div className={styles.menuIcon} onClick={this.openMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </div>

                <div  className={styles.menu} style={{ 
                    // width: this.state.menu.width,
                    maxWidth: this.state.menu.maxWidth
                    }}>
                    <div className={styles.padding}>
                        <div className={styles.top}>
                            <Link onClick={this.closeMenu}  fade to="/" duration={.66} hex="#fff">antoniolee.me</Link>
                            <img onClick={this.closeMenu} src={iconClose} alt=""/>
                        </div>
                        <div className={styles.middle}>
                            <Link
                                onClick={this.closeMenu} 
                                fade to="/"
                                className={styles.hiddenLink}
                                activeClassName={styles.active}
                                duration={.66} 
                                hex="#fff"
                            >
                                Home
                            </Link>
                            <Link
                                onClick={this.closeMenu} 
                                fade to="/about/"
                                className={styles.hiddenLink}
                                activeClassName={styles.active}
                                duration={.66} 
                                hex="#fff"
                            >
                                About
                            </Link>
                            <Link
                                onClick={this.closeMenu} 
                                fade to="/work/"
                                className={styles.hiddenLink}
                                activeClassName={styles.active}
                                duration={.66} 
                                hex="#000"
                            >
                                Work
                            </Link>
                            <Link
                                onClick={this.closeMenu} 
                                fade to="/resources/"
                                className={styles.hiddenLink}
                                activeClassName={styles.active}
                                duration={.66} 
                                hex="#000"
                            >
                                Resources
                            </Link>
                            <Link
                                onClick={this.closeMenu} 
                                fade to="/resume/"
                                className={styles.hiddenLink}
                                activeClassName={styles.active}
                                duration={.66} 
                                hex="#000"
                            >
                                Resume
                            </Link>
                        </div>
                        <div className={styles.end}>
                            <div className={styles.contact}>
                                <h4>Contact</h4>
                                <a href="mailto:antoniolee@outlook.com">antoniolee@outlook.com</a>
                            </div>
                            <div className={styles.social}>
                                <a href="https://www.linkedin.com/in/antoniohlee/"><img src={svgLinkedin} /></a>
                                <a href="https://codepen.io/antoniolee"><img src={svgCodepen} /></a>
                                <a href="https://www.instagram.com/antoniohlee/"><img src={svgInstagram} /></a>
                                <a href="https://twitter.com/antoniohlee"><img src={svgTwitter} alt="Twitter" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Menu